import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { ReactComponent as MyIcon } from '../assets/ak.svg';
import { RxSlash } from "react-icons/rx";


const Contact = () => {
    return (
        <div
            name="contact"
            className="w-full min-h-screen bg-[#b8bab9] text-gray-100 flex justify-center items-center flex-col lg:flex-col mx-auto z-50 relative"
        >
            <div className="flex flex-col md:flex-row px-2">
                <div className=" w-11/12 lg:w-7/12 justify-between flex flex-col gap-3">
                    <div className="mt-60">
                        <h1 className=" text-2xl lg:text-5xl font-semibold">INTERESTED IN WORKING TOGETHER?</h1>
                        <p className="flex flex-col lg:flex-row gap-2 md:gap-6">
                            <h2 className=" text-xl lg:text-4xl mt-2 font-mulish">aakashpatel315@gmail.com</h2>
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=aakashpatel315@gmail.com"
                                className="group text-white w-fit px-5 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-600 to-blue-500 cursor-pointer"
                            >Send Mail
                                <span className="group-hover:rotate-90 duration-300">
                                    <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
                <img src="/lucky-cat.jpg" draggable="false" alt="lucky-cat" className="w-64 lg:w-auto mx-auto" />
            </div>
            <div className=" flex w-full h-24 justify-center  items-center">
                <div className="flex hover:text-neutral-400 font-medium text-sm items-center text-white h-fit tracking-[0.2rem]">
                    <a
                        href="https://linkedin.com/in/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                    >   
                        LINKEDIN
                    </a>
                    <RxSlash size={14} className="mx-2" />   
                    <a
                        href="https://github.com/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                    >
                        GITHUB
                    </a>
                    <RxSlash size={14} className="mx-2" />
                    <a
                        href="https://github.com/aakashahpl"
                        className="hover:text-neutral-100 transition-colors duration-300"
                    >
                        INSTAGRAM
                    </a>
                </div>
                {/* <div className="flex-1 border">

                </div> */}
            </div>
        </div>

    );
};

export default Contact;
