import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Work from "./components/Work";

function App() {
  const [circle, setCircle] = useState(false); // State for showing/hiding custom cursor

  // Function to toggle cursor visibility
  const showCircle = () => {
    setCircle(!circle);
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (circle) {
        document.documentElement.style.setProperty('--x', `${e.clientX + window.scrollX}px`);
        document.documentElement.style.setProperty('--y', `${e.clientY + window.scrollY}px`);
      }
    };

    // Add the mousemove listener if cursor is visible, and remove it when it's hidden
    if (circle) {
      document.body.addEventListener('mousemove', handleMouseMove);
    } else {
      document.body.removeEventListener('mousemove', handleMouseMove);
    }

    // Cleanup event listener on unmount
    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove);
    };
  }, [circle]); // Depend on circle state


  useEffect(() => {

    // After the timeout, add the 'cursor-visible' class to the element with id 'circularcursor'
    const cursorElement = document.getElementById("circularcursor");
    if (cursorElement) {
      if (circle) {
        cursorElement.classList.add("cursor-visible");
        console.log("added visible clasdfasdf");
      }else{
        cursorElement.classList.remove("cursor-visible");
        console.log("in else part ")
      }
    }

    // Cleanup the timer when the component unmounts or `circle` changes


  }, [circle]);  // Runs every time `circle` changes


  return (
    <Router>
      <div id="circularcursor" className="cursor-hidden"></div>
      <Routes>
        <Route path="/" element={<Main showCircle={showCircle} />} />
        <Route path="/work" element={<Work />} />
      </Routes>
    </Router>
  );
}

export default App;
