import React from "react";
import NavBar from "./NavBar";
import Landing from "./Landing";
import About from "./About";
import Experience from "./Experience";
import Contact from "./Contact";
import SocialLinks from "./SocialLinks";

const Main = ({showCircle}) => {
  return (
    <div className="bg-[#1a1a1a]">
      <NavBar showCircle={showCircle} />
      <Landing showCircle={showCircle} />
      <About />
      {/* <Projects /> */}
      <Experience />
      {/* <Contact /> */}
      <SocialLinks />
    </div>
  );
};

export default Main;
