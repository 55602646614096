import React from "react";
import NavBar from "./NavBar";
import { div } from "framer-motion/client";

const Work = () => {

  return (
    <div className="bg-[#1a1a1a]">
      <h1>Work page</h1>;
    </div>
  );
};

export default Work;  